"use client";

import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { XMarkIcon } from "@heroicons/react/24/solid";

export interface HeaderAnnouncementProps {
    className?: string;
    children: React.ReactNode;
    hideOnScroll?: boolean;
    endsAt?: Date;
};

export default function HeaderAnnouncement(props: HeaderAnnouncementProps) {
    const [isVisible, setIsVisible] = useState<boolean>(true);
    const [hidden, setHidden] = useState<boolean>(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollHeight: number = (
                window.scrollY || document.documentElement.scrollTop || 
                document.body.scrollTop || 0
            );
            const height: number = document.documentElement.scrollHeight;
            setIsVisible(scrollHeight <= height * 0.1);
        };

        if (!props.hideOnScroll) return;
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [props.hideOnScroll]);

    if (props.endsAt && new Date() >= props.endsAt) return null;

    return (
        <motion.section
            className={
                `relative flex flex-col justify-center w-full 
                h-auto ${props.className}`
            }
            initial={!hidden && isVisible ? "visible" : "hidden"}
            animate={!hidden && isVisible ? "visible" : "hidden"}
            variants={{
                visible: {
                    opacity: 1,
                    display: "flex",
                    height: "auto",
                    transition: {
                        opacity: { duration: 0.3, ease: "easeInOut" },
                    },
                },
                hidden: {
                    opacity: 0,
                    height: 0,
                    display: "none",
                    transition: {
                        opacity: { duration: 0.3, ease: "easeInOut" },
                        height: { delay: 0.3 },
                        display: { delay: 0.6 },
                    },
                },
            }}
        >
            <span 
                className="px-4 py-2 me-8 text-xs lg:me-0 lg:text-sm 
                lg:text-center"
            >
                {props.children}
            </span>
            <XMarkIcon 
                className="absolute right-1 p-1 w-7 h-7
                rounded-full hover:bg-neutral-50/20 cursor-pointer"
                onClick={() => {
                    setIsVisible(false);
                    setHidden(true);
                }}
            />
        </motion.section>
    );
}
