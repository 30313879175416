"use client";

import Link from "next/link";
import { useTranslation } from "react-i18next";
import Routes from "@/app/constants/routes";
import Translations from "@/app/constants/translations";

export default function HeaderDashboardButton() {
    const { t } = useTranslation(Translations.HEADER);

    return (
        <Link 
            href={Routes.DASHBOARD}
            className="px-6 py-2 rounded-full bg-gradient-to-br 
            from-rose-500 to-rose-600 truncate 
            font-semibold backdrop-blur-sm shadow-lg text-center 
            dark:shadow-none hover:scale-105 hover:shadow-xl 
            transition-all ease-in-out duration-300"
        >
            <span 
                className="text-md text-neutral-50 font-bold 
                lg:text-lg"
            >
                {t(`${Translations.HEADER}:button.dashboard`)}
            </span>
        </Link>
    );
}
