export default class Routes {
    static readonly HOME = "/";
    static readonly PRIVACY_POLICY = "/privacy-policy";
    static readonly TERMS_OF_SERVICE = "/terms-of-service";
    static readonly REFUND_POLICY = "/refund-policy";
    static readonly LOGIN = "/auth/login";
    static readonly LOGOUT = "/auth/logout";
    static readonly REGISTER = "/auth/register";
    static readonly AUTH_VERIFY = "/auth/verify";
    static readonly AUTH_ERROR = "/auth/error";
    static readonly BLOG = "/blog";
    static readonly DASHBOARD = "/dashboard";
    static readonly DASHBOARD_AGREEMENTS = "/dashboard/agreements";
    static readonly DASHBOARD_SETTINGS = "/dashboard/settings";
    static readonly DASHBOARD_IMAGES = "/dashboard/images";
    static readonly DASHBOARD_PACKAGES = "/dashboard/packages";
    static readonly DASHBOARD_PAYMENT = "/dashboard/payment";
}
